import React, { FC, useCallback } from 'react';
import { Button } from '@mui/material';
import { Update } from '@mui/icons-material';

import { DashboardProps } from './@types';

export const Dashboard: FC<DashboardProps> = (props) => {
    const handleUpdateClick = useCallback(() => {
        fetch('/api/redeploy')
    }, []);

    return (
        <div>
            <Button
                startIcon={<Update />}
                variant="contained"
                onClick={handleUpdateClick}
            >
                Обновить страницы с Tilda
            </Button>
        </div>
    );
};
